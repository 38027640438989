import * as React from "react";
import { Link, graphql } from "gatsby";
import Nav from "../components/nav";
import Header from "../components/header";

const JoinSlackPage = ({ location }) => {
  console.log(location);
  //location.search
  const btn1 = {
    colour: "orange",
    text: "Join the Slack channel",
    link: "https://join.slack.com/t/alchemyboxworkspace/shared_invite/zt-qvv43zkg-m3HU3YbjV1w_jA~uyxzAMg",
  };
  const btn2 = { colour: "outline", text: "Finish", link: "/" };
  const header_button = [btn1, btn2];

  return (
    <main className="bg-white">
      <title>Alchemy Box - Join the Beta program</title>
      <Nav colour="grey" />
      <Header
        title="Join the beta program"
        colour="orange"
        subtitle={
          <div>
            <p className="mt-2 mb-12">
              The beta program is run via Slack, please fill out the form below
              to join the slack group and gain access to the beta program.
            </p>
          </div>
        }
        margin_bottom={24}
      />
      <div className="px-12 font-poppins bg-white text-abx-dark-blue pb-16">
        <div className="flex flex-col m-30 w-3/4 space-y-8 pt-12">
          <div className="flex flex-col">
            <input
              id="name"
              name="name"
              type="text"
              placeholder="What's your name? *"
              class="my-1 pt-4 pb-2 flex-grow outline-none text-abx-dark-blue border-abx-light-blue placeholder-abx-blue hover:border-abx-pink focus:border-abx-dark-blue border-b-2 focus-within:border-abx-dark-blue"
            />
          </div>
          <div className="flex flex-col">
            <input
              id="email"
              name="email"
              type="email"
              placeholder="What's your email? *"
              class="my-1 pt-4 pb-2 flex-grow outline-none text-abx-dark-blue border-abx-light-blue placeholder-abx-blue hover:border-abx-pink focus:border-abx-dark-blue border-b-2 focus-within:border-abx-dark-blue"
            />
          </div>
          <div className="flex flex-col">
            <select
              id="hear"
              name="hear"
              placeholder="How did you hear about us? *"
              class="fmy-1 pt-4 pb-2 flex-grow outline-none text-abx-dark-blue border-abx-light-blue placeholder-abx-blue hover:border-abx-pink focus:border-abx-dark-blue border-b-2 focus-within:border-abx-dark-blue"
            >
              <option value="google">Google</option>
              <option value="facebook">Facebook</option>
              <option value="instagram">Instagram</option>
              <option value="linkedin">LinkedIn</option>
              <option value="customer">I'm already a customer</option>
              <option value="friend">From a friend</option>
              <option value="other">Other</option>
            </select>
          </div>
          <div className="flex flex-col">
            <textarea
              id="getfrom"
              name="getfrom"
              placeholder="What do you want to get from the Slack group?"
              class="my-1 pt-4 pb-2 flex-grow outline-none text-abx-dark-blue border-abx-light-blue placeholder-abx-blue hover:border-abx-pink focus:border-abx-dark-blue border-b-2 focus-within:border-abx-dark-blue"
            />
          </div>
          <span className="mx-3 text-abx-dark-grey">* required</span>
          <div className="flex mx-3 space-x-3 py-5">
            <Link to="/">
              <button className="bg-transparent hover:bg-abx-pink p-3 rounded text-abx-dark-blue hover:text-white border-2 border-abx-dark-blue hover:border-transparent">
                &lt; Back
              </button>
            </Link>
            <Link to="/confirm">
              <button className="bg-abx-dark-blue hover:bg-abx-pink p-3 rounded text-white hover:text-white border-2 border-abx-dark-blue hover:border-abx-pink">
                Submit
              </button>
            </Link>
          </div>
        </div>
      </div>
    </main>
  );
};

export default JoinSlackPage;
